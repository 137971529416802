$primary-color: #26648e;
$primary-dark-color: #192a56;
$light-blue-color:#4f8fc0;
$blue-green-color:#53d2dc;
$cream-color:#ffe3b3;
$red-color:#e73845;
$green-color:#48bf53;
$yellow-grad-color:#e9b319;

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.text-center {
  text-align: center;
}

.justify-center {
  vertical-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-top {
  vertical-align: top;
}

.text-bold {
  font-weight: bold;
}

.content-container {
  min-height: calc(100vh - 160px);
  border-radius: 15px
}

.text-muted {
  color: #808080;
}

.app-logo {
  font-size: 20px;
  color: #000;
  float: left;
  min-width: 50px;
  padding-left: 20px;
}

.dropdown-notification-box {
  width: 350px;
  padding: 10px;
  padding-bottom: 20px;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ededed;
  border-radius: 10px;
  background-color: #fefefe;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}

.dropdown-profile-box {
  width: 300px;
  padding: 5px;
  padding-bottom: 20px;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ededed;
  border-radius: 10px;
  background-color: #fefefe;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}

.dropdown-extension-box {
  width: 400px;
  height: 600px;
  margin-bottom: 20px;
  overflow-y: auto;
  border: 1px solid #ededed;
  border-radius: 5px;
  background-color: #fefefe;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
  position: fixed;
  bottom: 9.4%;
  right: 1.6%;
}

.card {
  border-radius: 10px;
}

.box-shadow {
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}

.box-shadow-4 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}

.box-shadow-3 {
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}

.sider-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}

.box-shadow-2 {
  border-radius: 15px;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.19); */
}

.custom-box-shadow {
  box-shadow: -1px 5px 10px -2px rgba(105, 105, 105, 0.82);
  -webkit-box-shadow: -1px 5px 10px -2px rgba(105, 105, 105, 0.82);
  -moz-box-shadow: -1px 5px 10px -2px rgba(105, 105, 105, 0.82);
}

.table-collapse {
  border-collapse: collapse;
  border: 1px solid #ededed;
}

.table-default th,
.table-default td {
  padding: 10px;
}

.table-bottomborder th,
.table-bottomborder td {
  border-bottom: 1px solid #ededed;
}

.table-noborder th,
.table-noborder td {
  border: 0px;
}

.table-item th,
.table-item td {
  padding: 5px;
  vertical-align: top;
}

.table-collapse th,
.table-collapse td {
  border-collapse: collapse;
  border: 1px solid #ededed;
  border-radius: 5px;
}

.table-payroll th,
.table-payroll td {
  padding: 15px;
  text-align: center;
}

.table-period-title {
  background-color: #eff2f5;
}

.profile-item-box {
  padding: 10px;
}

.page-breadcrumb {
  margin-bottom: 10px;
}

.directory-item {
  min-height: 230px;
  text-align: center;
  margin: 10px;
}

.directory-division {
  padding-left: 10px;
}

.directory-item-image {
  border-radius: 50% !important;
  height: 6rem !important;
  width: 6rem !important;
}

.directory-item-detail {
  line-height: 1.2rem;
}

.directory-item-modal-box {
  width: 100%;
}

.directory-item-modal-image {
  border-radius: 50%;
  height: 10rem;
  width: 10rem;
  margin-bottom: 10px;
}

.attendance-table {
  pointer-events: none;
}

.attendance-action {
  pointer-events: auto;
}

.attendance-default {
  background-color: #bdbdbd;
}

.attendance-present {
  background: #2efe64;
}

.attendance-late {
  background-color: #f78181;
}

.attendance-leave {
  background-color: #e74c3c;
}

.attendance-absent {
  background-color: #000000;
  color: #bf0900;
  font-weight: 500;
}

.attendance-wfh {
  background-color: #000000;
  color: #ffffff;
  font-weight: 500;
}

.bg-light-grey {
  background-color: #fafafa;
}

/* hide year view */
.ant-radio-group {
  display: none !important;
}

.ant-picker-range {
  width: 100%;
}

.submission-tab .ant-tabs-tab {
  width: 180px;
}

.blinking-badge .ant-scroll-number {
  -webkit-animation: blink 2s infinite;
  -moz-animation: blink 2s infinite;
  -o-animation: blink 2s infinite;
  animation: blink 2s infinite;
}

.menu-text {
  color: #ffffff;
}

.menu-text:hover {
  color: #ffffff !important;
}

.menu-text:visited {
  color: #ffffff !important;
}

.menu-text:active {
  color: #ffffff !important;
}

.menu-text:visited {
  background-color: #ffffff !important;
}

.sider-submenu {
  background-color: $yellow-grad-color;
  color: black !important;
  margin: 0px !important;
  padding-top: 0px;
}

.sider-submenu:hover {
  background-color: $yellow-grad-color !important;
}

.list-menu {
  color: black;
}

.sider-text {
  color: black;
}

.instruction-box {
  background-color: #242830;
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
}

.ant-menu-submenu {
  margin: 0px !important;
  padding: 0px;
}

.ant-menu-item-selected {
  background-color: $yellow-grad-color !important;
  border-left: 3px solid #fff !important;
  color: black;
}

.ant-menu-item {
  margin: 0px !important;
}

.ant-menu-submenu-title {
  margin: 0px !important;
}

.ant-menu-submenu-active {
  background-color: $yellow-grad-color !important;
  border-left: 3px solid #fff !important;
  color: black !important;
}

.ant-menu-submenu-open {
  background-color: $yellow-grad-color !important;
  border-left: 3px solid #fff !important;
  color: black !important;
}

.ant-menu-submenu-selected {
  background-color: $yellow-grad-color !important;
  border-left: 3px solid #fff !important;
  color: black !important;
}

.sub-menu-text {
  color: #ffffff !important;
}

.ant-layout-sider-trigger {
  background-color: #3695e6 !important;
}

.ant-layout-sider-trigger:hover {
  background-color: #242830 !important;
}

.ant-menu-item-only-child.expand-control-active {
  background-color: rgba(39, 128, 211, 0) !important;
  /*Overriden property*/
}

.ant-menu-item-only-child.expand-control {
  background-color: rgba(39, 128, 211, 0) !important;
  /*Overriden property*/
}

@-webkit-keyframes blink {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes blink {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.sidebar-scroll::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.sidebar-scroll::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

.sidebar-scroll::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 50px;
}

.sidebar-scroll::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

.sidebar-scroll::-webkit-scrollbar-thumb:active {
  background: #000000;
}

.sidebar-scroll::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}

.sidebar-scroll::-webkit-scrollbar-track:hover {
  background: #666666;
}

.sidebar-scroll::-webkit-scrollbar-track:active {
  background: #333333;
}

.sidebar-scroll::-webkit-scrollbar-corner {
  background: transparent;
}

.button-primary {
  background-color: #29377d;
  border-color: #29377d;
  color: white;
}

.button-primary:hover {
  background-color: #222d68;
  border-color: #222d68;
  color: white;
}

.button-primary::after {
  background-color: #29377d;
  border-color: #29377d;
  color: white;
}

.button-primary:focus {
  background: #29377d;
  background-color: #29377d;
  border-color: #29377d;
  color: white;
}

.button-secondary {
  background-color: #e8b221;
  border-color: #e8b221;
  color: white;
}

.button-secondary:hover {
  background-color: #d1a11c;
  border-color: #d1a11c;
  color: white;
}

.button-secondary::after {
  background-color: #e8b221;
  border-color: #e8b221;
  color: white;
}

.button-secondary:focus {
  background: #e8b221;
  background-color: #e8b221;
  border-color: #e8b221;
  color: white;
}

.single-menu:hover {
  background-color: $yellow-grad-color;
}

.list-submenu {
  background-color: $primary-dark-color;
}

.list-submenu:hover {
  background-color: $yellow-grad-color;
}

.ant-menu-title-content {
  color: #fff !important;
}

.ant-menu-submenu-arrow {
  color: #fff !important;
}

.ant-menu-item-icon {
  color: #fff !important;
}

.button-handling {
  color: white;
  background-color: #273C75;
  border: none;

  &:hover {
    color: white;
    background-color: #364d87;
    border: none;
  }

  &:focus {
    color: white;
    background-color: #364d87;
    border: none;
  }
}

.button-part-request {
  color: white;
  background-color: #E9B319;
  border: none;

  &:hover {
    color: white;
    background-color: #f0bd30;
    border: none;
  }

  &:focus {
    color: white;
    background-color: #f0bd30;
    border: none;
  }
}

.button-completed {
  color: white;
  background-color: #27d118;
  border: none;

  &:hover {
    color: white;
    background-color: #2ae919;
    border: none;
  }

  &:focus {
    color: white;
    background-color: #2ae919;
    border: none;
  }
}

.ant-timeline-item-content {
  width: 100% !important;
}