.main-page {
    display: flex;
    height: 100%;
}

.left {
    flex: 1;
    background-color: #fff;
    height: 100vh;
    padding: 10px;
}

.right {
    width: 550px;
    height: 100%;
    padding: 100px 35px;
    align-items: center;
}

.left-content {
    margin: 40px 20px 0 20px;
}

.img-responsive {
    width: 85%;
    max-width: 900px;
    height: auto;
}

.left-content h1,
p {
    color: rgba(0, 0, 0, 0.85);
    font-family: 'Poppins', sans-serif;
}

.left-content h1 {
    font-size: 28px;
    font-weight: 600;
}

.left-content p {
    font-size: 20px;
    line-height: 32px;
}

.img-ilusstration {
    box-sizing: border-box;
    margin: auto;
}

.img-absolute {
    width: 60%;
    box-sizing: border-box;
    text-align: center;
    position: absolute;
    top: 120px;
}

.img-absolute img {
    text-align: center;
    width: 100%;
    max-width: 650px;
}

.app-title{
    font-size: 24px;
    font-family: MicrosoftTaiLe;
    font-weight: bold; 
    color: #29377d;
}

.content {
    display: flex;
    justify-content: space-between;
}

.content span {
    width: 290px;
    height: 150px;
}

.content-2 {
    display: flex;
    justify-content: space-between;
}

.content-2 span img {
    width: 85%;
    aspect-ratio: 2/1;
    object-fit: contain;
}

.center-qr {
    position: absolute;
    margin: 50px 0 20px 0;
    right: 56%;
    box-sizing: border-box;
}

.text-bottom {
    position: fixed;
    font-family: MicrosoftTaiLe;
    bottom: 15px;
    font-size: 16px;
    font-weight: bold;
    color: #29377d;
}

.row-bottom {
    width: 60%;
    position: fixed;
    bottom: 10px;
    z-index: 999;
    display: flex;
    justify-content: space-between;
}